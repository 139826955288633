import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import Img01 from '../../../images/Office/contact-img.jpg';
import ContactImg1400 from '../../../images/Office/contact-img1400.jpg';
import ScrollAnimation from 'react-animate-on-scroll';
import './RightBlock.scss'
import {NegotiatorDetail} from '../../../queries/common_use_query'
import GetStaff from "../../get-staff";
import { ShareIcon, PrintIcon } from "../../icons";
import SocialShare from "../../../share";
// markup
const RightBlock = (props) => {
  const {loading:loading, error:error, data:data} = NegotiatorDetail(props.negcrm_id);
  var cdetails = ""
  if( (props.brochure && props.brochure.length > 0) || (data && data.teams.length > 0) ) {
    cdetails= "cdetails-show"
  }

  return (
    <React.Fragment>
      <div className="right-block">
        {cdetails === "cdetails-show" &&
        <div className="contact-details">
          {/* <Link to={`/book-a-viewing?pid=${props.propertyid}`} className="btn btn-primary">Book a viewing </Link> */}
          {props.brochure && props.brochure.length > 0 &&
          <a href={props.brochure && props.brochure[0].srcUrl} target="_blank" className="btn btn-primary">Download Brochure </a>
          }
          {/* <a  target="_blank" className="btn btn-primary">Download Brochure </a> */}
          <GetStaff data={data}/>
          {/* <div className="contact-wrapper">
            <div className="contact-img">
              <picture>
                <source media="(min-width:992px)" srcSet={ContactImg1400} />
                <img src={Img01} alt="Img" />
              </picture>
            </div>
            <div className="contact-info ">
              <h3>Richard Burns</h3>
              <span className="profile">Branch manager</span>
              <span>
                <Link to="mailto:mail" className="dark-text">Send email</Link> or <br />call
                <Link to="tel:01708259539" className="tel"> 01708 259539</Link>
              </span>
            </div>
          </div> */}

        </div>
        }
        <div className ="d-flex flex-wrap align-self-center justify-content-between property-share">
          <div className="share-property"> 
            <SocialShare id={props.propertyid} type="property" class="btn btn-outline" />         
            {/* <a target="_blank" className="btn btn-outline"><ShareIcon/>Share</a> */}
          </div>
          <div className="print-property">       
            {props.brochure && props.brochure.length > 0 &&
            <a href={props.brochure && props.brochure[0].srcUrl} target="_blank" className="btn btn-outline"><PrintIcon />Print</a>
            }
          </div>
        </div>
        <div className="appraisal-bar d-none d-lg-block">
          <div className="left-block">
            <h2>What’s my property worth?</h2>
            <p>
              Selling or Letting? Get a free and up-to-date valuation from one of our experts.
            </p>
          </div>
          <div className="right-block">
            <Link to="/property-valuation/" className="btn">
              Book your market appraisal
            </Link>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}
export default RightBlock

