import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card, Tabs, Tab} from "react-bootstrap";
import "./FormBlock.scss";
import MortgageCalc from "../../calculators/mortgagecalc/property-mortgage";
import StampDutyCalc from "../../calculators/stampdutycalc/property-stampduty";
import RentYieldCalc from "../../calculators/rentalyieldcalc/index";

const FormBlock = (props) => {
  
  return (
    <React.Fragment>
      
          <div className="form-block detail-calc-block">
            <Container>
                <Row>
                    <Col md="12" lg="8">
                        <div className="tab-box">
                    {props.search_type === "sales" &&
                    <Tabs defaultActiveKey="mortgage-calculator">                    
                    <Tab eventKey="mortgage-calculator" title="Mortgage Calculator">
                        <div className="tab-content-block">
                            <span className="form-text">Calculate your monthly mortgage payments.</span>
                            <MortgageCalc propertyPrice={props.propertyValue} />
                          
                        </div>
                    </Tab>
                    <Tab eventKey="stamp-calculator" title="Stamp Duty Calculator">
                        <div className="tab-content-block">
                            <StampDutyCalc propertyValue={props.propertyValue}/>
                        </div>
                    </Tab>
                    
                   
                  </Tabs>
                    }
                    {props.search_type === "lettings" &&
                    <Tabs defaultActiveKey="rental-calculator">                    
                    
                    
                    <Tab eventKey="rental-calculator" title="Rental Yield Calculator">
                    <div className="tab-content-block">
                    <span className="form-text">You can quickly work out the return on your buy to let investment using our yield calculator.</span>
                        <RentYieldCalc propertyPrice={props.propertyValue}/>
                    </div>
                    </Tab>
                  </Tabs>
                    }
                        </div>
                    </Col>
                </Row>
            </Container>            
            </div>
            
    </React.Fragment>
  );
};
export default FormBlock;
