import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import img375 from '../../../images/property-details/img1.jpg'
import Slider from "react-slick";
import { PropertyLists } from "./SearchItems";
import axios from 'axios';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform"
import './PropertyList.scss'
const settings = {
  dots: false,
  speed: 800,
  infinite: false,
  arrows: false,
  mobileFirst: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 3020,
      settings: {
        arrows: false,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 1199,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 991,
      settings: {
        dots: false,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    }
  ]
};
// markup
const PropertyList = (props) => {
  const [propItems, setPropItems] = useState([])
	const getitems = async url => {
		try {
			const { data } = await axios.get(url, {})
			if (data && data.length > 0) {
				setPropItems(data)
			}
		} catch (error) {
			console.error(error)
		}
	}
  useEffect(() => {
		let url = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/Property-details?pid=" + props.id;
		getitems(url)
    //console.log('propItems',url,propItems);
  },[])
  var searchurl = props.search_type ==="sales"?'/properties-for-sale/'+props.address.area.toLowerCase().replace(/ /g,"-") :'/properties-to-rent/'+props.address.area.toLowerCase().replace(/ /g,"-")

  return (
    <React.Fragment>
    {propItems && propItems.length > 0 && 
      <div className="property-list-block similar-detail-property">
        
          <Container>
            <Row>
              <Col md="12">
                <div className="block-title d-flex">
                  <h2>Similar Properties near {props.address.street_name.replace(/,/g, "")}, {props.address.area}</h2>                  
                  <button type="button" className="btn-circle more-arrow-btn">
                  <Link to={searchurl+'/'}>
                    <i className="icon-arrow"></i>
                  </Link>
                  </button>                  
                </div>
              </Col>
            </Row>
            <Slider className="property-slider" {...settings}>
              {/* <div className="property-slider"> */}
              {propItems && propItems.map((item, key) => {
              let processedImages = JSON.stringify({});
              if (item?.imagetransforms?.images_Transforms) {
                  processedImages = item.imagetransforms.images_Transforms;
              }       
              var checkptype = item.search_type ==="sales"?'/property-for-sale/' :'/property-to-rent/'
              return <>
                <div className="search-item-box">
                  <div className="search-property-img img-zoom">
                    <Link to={`${checkptype}${item.slug}-${item.id}/`} className="img-zoom">
                    <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename="property.images.similarpropertytile"attr={{ alt: item.display_address+ ' -  Balgores' }}
                        imagetransformresult={processedImages} id={item.id} testparam={true} />
                    </Link>
                    <span className="property-tag">
                      {item.status}
                    </span>
                  </div>
                  <div className="search-property-details">
                    <button type="button" className="like-btn d-none">
                      <i className="icon-heart-outline"></i>
                    </button>
                    <h2>
                      <Link to={`${checkptype}${item.slug}-${item.id}/`}>{item.display_address} </Link>
                    </h2>
                    <span className="search-property-text">
                      {item.title}
                    </span>
                    <span className="property-price">
                    {'£' + item.price.toLocaleString()} <span>{item.search_type === "lettings"?"pcm":item.price_qualifier}</span>
                    </span>

                  </div>
                </div>
                </>
              })}

              {/* </div> */}
            </Slider>
          </Container>
        
      </div>
    }
    </React.Fragment>
  )
}
export default PropertyList