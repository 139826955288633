import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../../Components/Play/PlayVideo";
import './AreaGuide.scss'
import { AreaGuideDetail } from '../../../queries/common_use_query'
import { AltSiteName, AreaguideBaseURL } from "../../utils"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform"
import getVideoId from "get-video-id"
import parse from 'html-react-parser';

// markup
const AreaGuide = (props) => {
    const {loading:loading, error:error, data:data} = AreaGuideDetail(props.postcode);
    const [isPlay, setPlay] = useState(false);
    const [VideoID, setVideoID] = useState(false);

    return (
        <React.Fragment>
            {data && data.areaguides.map((item,index) => {
            let processedImages = JSON.stringify({});
            if (item?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = item.imagetransforms.Tile_Image_Transforms;
            }
            const videoid = item.Youtube_Video_URL && getVideoId(item.Youtube_Video_URL);
            var str = item.Postcode_Mapping;
            var search = props.postcode;
            var arr = str.split(", ");
            if (arr.indexOf(search) !== -1) {
            return<>
            <div className="area-guide">
                <Container>
                    <Row className="row-wrap">
                        <Col md="12" lg="6" className="pe-lg-0 block-img img-zoom">
                            
                            <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="areaguides.Tile_Image.commontile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Name+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>  

                                    {item.Youtube_Video_URL && videoid ?
                                        <button
                                            type="button"
                                            className="btn-play"
                                            onClick={(e) => { setPlay(true); setVideoID(videoid.id);}}

                                        >
                                            <i className="icon-play"></i>
                                        </button>
                                    :""}
                            
                        </Col>
                        <Col md="12" lg="6" className="ps-lg-0">
                            
                                <div className="areaguide-content">
                                    <h2>Living in {item.Name}</h2>
                                    {parse(item.Short_Intro)}
                                    {/* <Link to={`${AreaguideBaseURL}/${item.URL}-area-guides`} className="btn">View area guide</Link> */}
                                </div>
                            
                        </Col>
                    </Row>
                </Container>
            </div>
            </>
            }
            })}
            {VideoID &&
                <PlayVideo
                    isOpen={isPlay}
                    isCloseFunction={setPlay}
                    videoId={VideoID}
                    isAutoPlay={1}
                />
            }
        </React.Fragment>
    )
}
export default AreaGuide